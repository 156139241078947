import ISecurityService from "./security.interface";
import getJiraGroupsMock from "../../assets/mockups/jira_groups.json"
import getAuthorizedGroupsMock from "../../assets/mockups/authorized_groups.json"
import updateAuthorizedGroupsMock from "../../assets/mockups/update_authorized_groups.json"

class SecurityServiceMock implements ISecurityService {
    async getJiraGroups(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: getJiraGroupsMock});
        });
    }
    async getAuthorizedGroups(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: getAuthorizedGroupsMock});
        });
    }
    async updateAuthorizedGroups(payload: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: updateAuthorizedGroupsMock});
        });
    }
    
}

export default new SecurityServiceMock();