import IObjectTypeService from "./object_type.interface";
import createMock from "../../assets/mockups/new_object.json";
import updateMock from "../../assets/mockups/update_object_type.json";
import deleteMock from "../../assets/mockups/new_object.json";
import updateStateMock from "../../assets/mockups/update_object_type_state.json"
import dropdownMock from "../../assets/mockups/object_type_form_dropdowns.json"

class ObjectTypeServiceMock implements IObjectTypeService {
    async create(payload: any) {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: createMock});
        });
    }
    async update(id: number, payload: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: updateMock});
        });
    }
    async delete(id: number, payload: any) {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: deleteMock});
        });
    }
    async updateState(id: number, payload: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: updateStateMock});
        });
    }
    async dropdowns(id: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: dropdownMock});
        });
    }
}

export default new ObjectTypeServiceMock();

