import { createWebHistory, createRouter } from "vue-router";
import SyncView from "../views/SyncView.vue";
import SettingsView from "../views/SettingsView.vue";
import SecurityView from "../views/SecurityView.vue";
import { getLocale } from "../helpers/utils";
import i18n from '../i18n'
import moment from "moment";

const routes = [
  {
    path: '/',
    name: "Home",
    component: SyncView
  },
  {
    path: "/sync",
    name: "Sync",
    component: SyncView,
  },
  {
    path: "/settings",
    name: "Settings",
    component: SettingsView,
  },
  {
    path: "/security",
    name: "Security",
    component: SecurityView,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async(to, from, next) => {
  const locale = await getLocale();
  console.log("set language: ", locale);
  i18n.global.locale = (locale || "en");
  moment.locale(i18n.global.locale);
  const initPages = ['/', '/index.html'];
  const isInitPage = initPages.includes(to.path);
  if (isInitPage) {
    next('/sync');
  } else {
    next();
  }
});

export default router;