import IRoleService from "./role.interface";
import getMock from "../../assets/mockups/read_user_roles.json"

class RoleServiceMock implements IRoleService {
    async get() {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: getMock});
        });
    }
}

export default new RoleServiceMock();