import { responseHelper } from "@/helpers/responseHelper";
import CONFIG from "../helpers/config";
import FieldService from '../services/field/field.service';
import FieldServiceMock from '../services/field/field.service.mock';

const payload: any = null;
const initialState = { status: { result: true }, response: payload }

const service = CONFIG.useMockups ? FieldServiceMock : FieldService;

export default {
    namespaced: true,
    state: initialState,
    actions: {
        create(store: any, payload: any) {
            return responseHelper(store, 'create', service.create(payload));
        },
        createOnInsight(store: any, payload: any) {
            return responseHelper(store, 'createOnInsight', service.createOnInsight(payload.insight_id, payload));
        },
        delete(store: any, payload: any) {
            return responseHelper(store, 'delete', service.delete(payload.id, payload));
        },
        dropdowns(store: any, payload: any) {
            return responseHelper(store, 'dropdowns', service.dropdowns(payload.insight_id));
        },
    },
    mutations: {
        createSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        createFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        },
        createOnInsightSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        createOnInsightFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        },
        deleteSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        deleteFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        },
        dropdownsSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        dropdownsFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        }
    }
};