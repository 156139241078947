import { responseHelper } from "@/helpers/responseHelper";
import CONFIG from "../helpers/config";
import RoleService from '../services/role/role.service';
import RoleServiceMock from '../services/role/role.service.mock';

const payload: any = null;
const initialState = { status: { result: true }, response: payload }

const service = CONFIG.useMockups ? RoleServiceMock : RoleService;

export default {
    namespaced: true,
    state: initialState,
    actions: {
        get(store: any) {
            return responseHelper(store, 'get', service.get());
        }
    },
    mutations: {
        getSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        getFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        }
    }
};