import { fetchHelper } from "@/helpers/fetchHelper";
import IObjectSchemaService from "./object_schema.interface";

class ObjectSchemaService implements IObjectSchemaService {
    async get() {
        return await fetchHelper('object-schema-imports', 'GET');
    }
    async create(payload: any) {
        return await fetchHelper('object-schema-imports', 'POST', payload);
    }
    async update(id: number, payload: any): Promise<any> {
        return await fetchHelper(`object-schema-imports/${id}`, 'PATCH', payload);
    }
    async delete(id: number, payload: any) {
        return await fetchHelper(`object-schema-imports/${id}`, 'DELETE', payload);
    }
    async sync(id: number, payload: any) {
        return await fetchHelper(`object-schema-imports/${id}/sync`, 'POST', payload);
    }
    async syncStatus(url: string): Promise<any> {
        return await fetchHelper(url, 'GET');
    }
    async insightObjectSchemas(): Promise<any> {
        return await fetchHelper('insight/object-schemas/dropdown', 'GET');
    }
}

export default new ObjectSchemaService();