import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import store from './store/index';
import router from './router';
import PrimeVue from 'primevue/config';
import AccordionComponent from 'primevue/accordion';
import AccordionTabComponent from 'primevue/accordiontab';
import PanelComponent from 'primevue/panel';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import './assets/global.css'

import Select2Component from './components/Select2Component.vue';
import InputComponent from './components/InputComponent.vue';
import TextAreaComponent from './components/TextAreaComponent.vue';
import Dialog2Component from './components/Dialog2Component.vue';
import MultiSelectComponent from './components/MultiSelectComponent.vue';
import LoadingComponent from './components/LoadingComponent.vue';

const app = createApp(App);

app.use(i18n);
app.use(router);
app.use(store);
app.use(PrimeVue);

app.component('AccordionComponent', AccordionComponent);
app.component('AccordionTabComponent', AccordionTabComponent);
app.component('Select2Component', Select2Component);
app.component('InputComponent', InputComponent);
app.component('TextAreaComponent', TextAreaComponent);
app.component('Dialog2Component', Dialog2Component);
app.component('PanelComponent', PanelComponent);
app.component('MultiSelectComponent', MultiSelectComponent);
app.component('LoadingComponent', LoadingComponent);

app.mount('#app')
