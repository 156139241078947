import CONFIG from "../helpers/config";
import { requestHeader } from "./requestHeader";
import i18n from '../i18n';

export const fetchHelper = async (path: any, method: string, payload: any | null = null) => {

    try {
        const requestInit: RequestInit = await requestHeader(method, payload)
        const res = await fetch(CONFIG.baseUrl + path, requestInit);
        const resource: any = i18n.global.tm('backend');
        if (!res.ok) {
            const contentType = res.headers.get("content-type");
            if (contentType && contentType.indexOf("text/html") !== -1) {
                return { result: false, data: { "message" : res.statusText, "status_code": res.status }, status_code: res.status };
            } else {
                const error = await res.json();
                const customError = error
                if (error.message) {
                    customError["message"] = resource[`${error.message}`] || error.message
                }
                if (res.status == 400) {
                    customError["errors"] = getErrors(resource, error.errors);
                }
                customError["status_code"] = res.status;
                return { result: false, data: customError, status_code: res.status };
            }
        } else {
            res.status
            const data = res.status == 201 || res.status == 204 ? {} : await res.json();
            const customData = data;
            if (data && data.message) {
                customData["message"] = resource[`${data.message}`] || data.message
            }
            customData["status_code"] = res.status;
            return { result: true, data: customData };
        }
    } catch (error) {
        return { result: false, data: error };
    }
};


const getErrors = (resources: any, errors: any): any[] => {
    if (!errors) return [];
    const customErrors: any[] = [];
    errors.map((error: any) => {
        customErrors.push({
            key: error.key,
            message: resources[`${error.message}`] || error.message
        })
    });
    return customErrors;

}