<template>
  <div class="aui">
    <table class="aui">
      <thead>
        <tr>
          <th class="aui-table-column-issue-key">Id</th>
          <th class="sorter-priority">{{ $t("device42_attribute") }}</th>
          <th>
            <a
              v-if="isAdmin"
              href="#"
              type="button"
              @click="openDialogNewAttributeOnInsight()"
            >
              <span
                class="aui-icon aui-icon-small aui-iconfont-add"
                :title="$t('create_new_attribute_in_insight')"
              ></span></a
            >{{ $t("insight_attribute") }}
          </th>
          <th>
            <a
              data-aui-trigger
              :aria-controls="'object-type-1-' + index"
              :href="'#object-type-1-' + index"
            >
              <span
                class="aui-icon aui-icon-small aui-iconfont-info-filled"
              ></span>
            </a>
            <aui-inline-dialog :id="'object-type-1-' + index">
              <small> {{ $t("object_attribute_mapping_message") }}</small>
            </aui-inline-dialog>
            {{ $t("object_attribute_mapping") }}
          </th>
          <th class="w-20">
            <a
              data-aui-trigger
              :aria-controls="'object-type-2-' + index"
              :href="'#object-type-2-' + index"
            >
              <span
                class="aui-icon aui-icon-small aui-iconfont-info-filled"
              ></span>
            </a>
            <aui-inline-dialog :id="'object-type-2-' + index">
              <ul>
                <li>
                  <small>{{ $t("query_doql_message_1") }}</small>
                </li>
                <li>
                  <small>{{ $t("query_doql_message_2") }}</small>
                </li>
                <li>
                  <small>
                    <b>{{ $t("example") }}: </b> select businessapplication_fk,
                    device_fk from view_businessapplicationelement_v1
                  </small>
                </li>
              </ul>
            </aui-inline-dialog>
            {{ $t("query_doql") }}
          </th>
          <th class="w-5">
            <a
              data-aui-trigger
              :aria-controls="'object-type-3-' + index"
              :href="'#object-type-3-' + index"
            >
              <span
                class="aui-icon aui-icon-small aui-iconfont-info-filled"
              ></span>
            </a>
            <aui-inline-dialog :id="'object-type-3-' + index">
              <ul>
                <li>
                  <small>
                    {{ $t("the_value") }} <b>${businessapplication_pk}</b>
                    {{ $t("query_doql_message_3") }}
                  </small>
                </li>
                <li>
                  <small>
                    <b>{{ $t("example") }}: </b> businessapplication_fk =
                    ${businessapplication_pk}
                  </small>
                </li>
              </ul>
            </aui-inline-dialog>
            {{ $t("internal_filter") }}
          </th>
        </tr>
        <tr></tr>
      </thead>
      <tbody>
        <tr v-if="isAdmin && !loading">
          <td>
            <div class="checkbox">
              <input
                v-model="field.is_id"
                class="checkbox"
                type="checkbox"
                name="is_id"
                id="is_id"
              />
            </div>
          </td>
          <td>
            <form class="aui">
              <input
                v-model="field.device42_key"
                class="text w-100"
                type="text"
                id="device42_key"
                name="device42_key"
              />
            </form>
          </td>
          <td>
            <select-2-component
              v-model="field.insight_id"
              :id="'insight_field_id-' + index"
              :options="dropdowns.insight_attributes"
              :get-info="getInfoInsightAttribute"
              :hideLabel="true"
            />
          </td>
          <td>
            <select-2-component
              v-if="has_reference"
              v-model="field.object_mapping"
              :id="'object_mapping-' + index"
              :options="referecens_by_insight_attribute"
              :get-info="getInfoReferenceByInsightAttribute"
              :hideLabel="true"
            />
          </td>
          <td>
            <form class="aui">
              <input
                v-if="is_many_to_many"
                v-model="field.many_to_many_query"
                class="text w-100"
                type="text"
                id="many_to_many_query"
                name="many_to_many_query"
              />
            </form>
          </td>
          <td>
            <form class="aui">
              <input
                v-if="is_many_to_many"
                v-model="field.internal_filter"
                class="text w-100"
                type="text"
                id="internal_filter"
                name="internal_filter"
              />
            </form>
          </td>
          <td class="text-align-right">
            <div class="buttons text-align-right">
              <button
                class="aui-button aui-button-primary"
                @click="add($event)"
              >
                {{ $t("add") }}
              </button>
            </div>
          </td>
        </tr>
        <tr v-if="loading">
          <td colspan="6">
            <div class="custom-row-center">
              <aui-spinner size="medium"></aui-spinner>
            </div>
            <div class="custom-row-center">
              <small>{{ $t("loading_insight_attribute_information") }}</small>
            </div>
          </td>
        </tr>
        <tr
          v-for="(field, index) in orderFields(objectTypeFields)"
          :key="field.id"
          v-bind:class="{ 'attribute-failed': field.status == 'FAILED' }"
        >
          <td>
            <IconInfoComponent 
              v-if="field.status == 'FAILED'" 
              :identifier="'attribute-info-' + field.id"
              :message="field.message" />
            <span
              v-if="field.is_id && field.status != 'FAILED'"
              class="aui-icon aui-icon-small aui-iconfont-check"
              >Id</span
            >
          </td>
          <td>
            {{ field.device42_name }}
          </td>
          <td>{{ field.insight_name }}</td>
          <td>{{ field.object_mapping }}</td>
          <td>
            <div
              class="webkit-box-text text-collapse"
              @click="displayText($event)"
            >
              {{ field.many_to_many_query }}
            </div>
          </td>
          <td>
            <div
              class="webkit-box-text text-collapse"
              @click="displayText($event)"
            >
              {{ field.internal_filter }}
            </div>
          </td>
          <td class="text-align-right">
            <div class="buttons text-align-right">
              <button
                v-if="isAdmin"
                class="aui-button aui-button-danger"
                @click="deleteField($event, field.id, index)"
              >
                {{ $t("remove") }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <create-attribute-on-insight-component
      v-if="open_dialog_new_attribute_on_insight && isAdmin"
      :dialogId="dialogId"
      :objectTypeInsightId="objectType.insight_id"
      :typeAttributeValues="dropdowns.type_attribute_values"
      :createNewAttributeOnInsight="createNewAttributeOnInsight"
      :validation="attribute_insight_validation"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { showAlert } from "../helpers/utils.js";
import * as aui from "@atlassian/aui";
import * as $ from "jquery";
import CreateAttributeOnInsightComponent from "./CreateAttributeOnInsightComponent.vue";
import IconInfoComponent from "./IconInfoComponent.vue";

export default defineComponent({
  components: {
    CreateAttributeOnInsightComponent,
    IconInfoComponent
  },
  props: {
    index: Number,
    isAdmin: Boolean,
    objectType: Object,
    fields: Array,
    objectSchemaIndex: Number,
    objectTypeIndex: Number,
    objectSchemaIndexSelected: Number,
    objectTypeIndexSelected: Number,
  },
  data() {
    return {
      loading: false,
      dialogId: "create-new-attribute-on-insight-" + this.objectType.id,
      objectTypeFields: this.fields,
      field: {
        object_type_id: this.objectType.id,
      },
      dropdowns: {
        insight_attributes: [],
        type_attribute_values: [],
      },
      insight_attributes: [],
      referecens_by_insight_attribute: [],
      has_reference: false,
      is_many_to_many: false,
      open_dialog_new_attribute_on_insight: false,
      attribute_insight_validation: null,
    };
  },
  watch: {
    objectTypeIndexSelected(
      newObjectTypeIndexSelected,
      oldObjectTypeIndexSelected
    ) {
      if (
        !this.loading &&
        this.objectSchemaIndex === this.objectSchemaIndexSelected &&
        this.objectTypeIndex === this.objectTypeIndexSelected
      )
        this.getObjectTypesDropdowns();
    },
  },
  methods: {
    add(event) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      const self = this;
      self.field.device42_name = self.field.device42_key;
      const payload = self.field;
      this.$store
        .dispatch("field/create", payload)
        .then(
          (response) => {
            self.objectTypeFields = response;
            self.has_reference = false;
            self.is_many_to_many = false;
            this.field = {
              object_type_id: this.objectType.id,
            };
            self.$forceUpdate();
            aui.$("#insight_field_id-" + this.index).select2("val", "");
            aui.$("#object_mapping-" + this.index).select2("val", "");
            showAlert(
              "success",
              this.$t("the_field_was_successfully_registered")
            );
          },
          (error) => {
            if (error.errors && error.errors.length > 0) {
              showAlert("error", error.errors[0].message);
            } else {
              showAlert("error", error.message);
            }
          }
        )
        .finally(() => {
          event.target.idle();
        });
    },
    getInfoInsightAttribute(object) {
      if (!object) {
        return;
      }
      this.field.insight_name = object.name;
      this.field.is_reference = object.reference_object_type_id != null;
      this.field.is_many_to_many = object.maximum_cardinality === -1;
      this.has_reference = this.field.is_reference;
      this.is_many_to_many = this.field.is_many_to_many;
      this.field.reference_object_type_id = null;
      if (this.field.is_reference) {
        this.field.reference_object_type_id = object.reference_object_type_id;
        this.referecens_by_insight_attribute = object.relationship_attributes;
      }
      if (!this.is_many_to_many) {
        delete this.field.many_to_many_query;
        delete this.field.internal_filter;
      }
    },
    getInfoReferenceByInsightAttribute(object) {
      if (!object) {
        delete this.field.object_mapping;
        return;
      }
      if (this.field.is_reference) this.field.object_mapping = object.name;
      else delete this.field.object_mapping;
    },
    deleteField(event, fieldId, index) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      const self = this;
      this.$store
        .dispatch("field/delete", { id: fieldId })
        .then(
          (response) => {
            self.objectTypeFields.splice(index, 1);
            self.$forceUpdate();
            showAlert("success", this.$t("the_field_was_successfully_deleted"));
          },
          (error) => {
            showAlert("error", error.message);
          }
        )
        .finally(() => {
          event.target.idle();
        });
    },
    openDialogNewAttributeOnInsight() {
      this.open_dialog_new_attribute_on_insight = true;
      setTimeout(() => {
        aui.dialog2("#" + this.dialogId).show();
      }, 100);
    },
    createNewAttributeOnInsight(event, newAttribute) {
      const self = this;
      this.$store
        .dispatch("field/createOnInsight", newAttribute)
        .then(
          (response) => {
            self.open_dialog_new_attribute_on_insight = false;
            self.dropdowns.insight_attributes.push(response);
            self.$forceUpdate();
            showAlert(
              "success",
              this.$t("the_attribute_was_successfully_registered")
            );
            aui.dialog2("#" + self.dialogId).hide();
            self.attribute_insight_validation = null;
          },
          (error) => {
            showAlert("error", error.message);
            self.attribute_insight_validation = error;
          }
        )
        .finally(() => {
          event.target.idle();
        });
    },
    getObjectTypesDropdowns() {
      this.loading = true;
      console.log("field/dropdowns");
      this.$store
        .dispatch("field/dropdowns", { insight_id: this.objectType.insight_id })
        .then(
          (response) => {
            this.dropdowns = response;
          },
          (error) => {
            showAlert("error", error.message);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    displayText(event) {
      const target = $(event.target);
      if (target.hasClass("webkit-box-text"))
        target.removeClass("webkit-box-text");
      else target.addClass("webkit-box-text");
    },
    orderFields(fields) {
      return fields.sort((first, second) => first.id - second.id);
    },
  },
  mounted() {
    $(".aui").on("submit", function (e) {
      e.preventDefault();
    });
  },
});
</script>

<style>
.attribute-failed {
  background: #ffcccb !important;
}
</style>
