<template>
  <div>
    <div class="aui-page-header" style="margin-left: 5rem">
      <div class="aui-page-header-inner">
        <div class="aui-page-header-main">
          <h3 class="component-heading"> {{ $t("update_object_sync") }} </h3>
        </div>
      </div>
    </div>
    <div class="aui-group">
      <div class="aui-item">
        <form
          class="aui text-align-left"
          style="margin-right: 10rem; margin-left: 5rem"
        >
          <fieldset>
            <text-area-component
              v-model="object.doql"
              label="DOQL"
              id="doql"
              :required="true"
              :validationKeys="['DOQL']"
              :validation="validation"
              :description="$t('doql_description')"
            />
            <div class="buttons-container">
              <div class="buttons text-align-right">
                <button
                  type="button"
                  class="aui-button aui-button-primary"
                  @click="updateObjectType($event, object)"
                >
                  {{ $t("save") }}
                </button>
                <a href="#" type="button" class="cancel" @click="closeForm()"
                  >{{ $t("cancel") }}</a
                >
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    closeForm: Function,
    updateObjectType: Function,
    objectType: Object,
    validation: Object
  },
  data() {
    return {
      object: this.objectType,
    };
  },
  methods: {
    
  },
});
</script>

<style>
</style>