import { getToken, getLocale } from './utils';

export async function requestHeader(method: string, payload: any = null) {
    const token = await getToken()
    const locale = await getLocale()
    const headers: any = {
        "Authorization": token,
        "Accept-Language": locale,
        "Content-Type": "application/json",
    };
    const request: RequestInit = { method: method, headers: headers }
    if (method == "POST" || method == "PUT" || method == "PATCH")
        request.body = JSON.stringify(payload);
    return request;
}