import { responseHelper } from "@/helpers/responseHelper";
import CONFIG from "../helpers/config";
import SecurityService from '../services/security/security.service';
import SecurityServiceMock from '../services/security/security.service.mock';

const payload: any = null;
const initialState = { status: { result: true }, response: payload }

const service = CONFIG.useMockups ? SecurityServiceMock : SecurityService;

export default {
    namespaced: true,
    state: initialState,
    actions: {
        getJiraGroups(store: any) {
            return responseHelper(store, 'getJiraGroups', service.getJiraGroups());
        },
        getAuthorizedGroups(store: any) {
            return responseHelper(store, 'getAuthorizedGroups', service.getAuthorizedGroups());
        },
        updateAuthorizedGroups(store: any, payload: any) {
            return responseHelper(store, 'updateAuthorizedGroups', service.updateAuthorizedGroups(payload));
        }
    },
    mutations: {
        getJiraGroupsSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        getJiraGroupsFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        },
        getAuthorizedGroupsSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        getAuthorizedGroupsFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        },
        updateAuthorizedGroupsSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        updateAuthorizedGroupsFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        }
    }
};