<template>
  <div class="field-group">
    <label :for="id"
      >{{ label
      }}<span v-if="required" class="aui-icon icon-required"
        ></span
      ></label
    >
    <select :id="id" :name="id" :placeholder="placeholder" multiple>
      <option disabled value="">{{ $t("select_an_option") }}</option>
      <option
        v-for="option in options"
        :key="option.id"
        :value="option.id"
        :selected="option.selected"
        v-html="option.text"
      ></option>
    </select>
    <div class="description">{{ description }}</div>
  </div>
</template>

<script>
import * as aui from "@atlassian/aui";

export default {
  name: "custom-multi-select",
  props: {
    value: Number,
    label: String,
    id: String,
    placeholder: String,
    options: Array,
    required: Boolean,
    getInfo: Function,
    description: String,
    addElement: Function,
    removeElement: Function
  },
  mounted() {
    const self = this;
    aui.$("#" + this.id)
      .auiSelect2()
      .on("change", function (e) {
        if (e.added) {
          self.addElement(e.added.id);
        }
        else if (e.removed)
          self.removeElement(e.removed.id);
      });
  },
};
</script>
<style>

</style>