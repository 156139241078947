import { fetchHelper } from "@/helpers/fetchHelper";
import ISettingsService from "./settings.interface";

class SettingsService implements ISettingsService {
    async getCredentials(): Promise<any> {
        return await fetchHelper('plugin/credentials', 'GET');
    }
    async saveDevice42Credentials(payload: any): Promise<any> {
        return await fetchHelper('atlassian-connect/device42/credentials', 'PUT', payload);
    }
}

export default new SettingsService();