import IFieldService from "./field.interface";
import createMock from "../../assets/mockups/new_field.json";
import createAttributeMock from "../../assets/mockups/create_attribute.json"
import dropdownsMock from "../../assets/mockups/field_dropdown.json";

class FieldServiceMock implements IFieldService {
    async create(payload: any) {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: createMock });
        });
    }
    async createOnInsight(insightId: string, payload: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: createAttributeMock });
        });
    }
    async delete(id: number, payload: any) {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: {} });
        });
    }
    async dropdowns(insightId: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: dropdownsMock });
        });
    }
}

export default new FieldServiceMock();

