<script>
import { defineComponent } from 'vue'
import AccordionTab from 'primevue/accordiontab'

export default defineComponent({
  name: 'AccordionTab', // must be named "AccordionTab" for Accordion to detect it
  extends: AccordionTab,
  props: {
    variant: Number,
  },
  render: AccordionTab.render,
})
</script>
