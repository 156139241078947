<template>
  <section
    :id="id"
    class="aui-dialog2 aui-dialog2-medium aui-layer"
    style="top: 350px"
    role="dialog"
    tabindex="-1"
  >
    <header class="aui-dialog2-header">
      <h3 class="aui-dialog2-header-main">{{ title }}</h3>
      <button class="aui-close-button" type="button"></button>
    </header>
    <div class="aui-dialog2-content">
      <p>{{ description }}</p>
    </div>
    <footer class="aui-dialog2-footer">
      <div class="aui-dialog2-footer-actions">
        <button class="aui-button aui-button-primary" @click="onOk($event)">
          {{ buttonLabel }}
        </button>
        <button class="aui-button aui-button-link" @click="closeForm()">
          {{ $t('cancel') }}
        </button>
      </div>
    </footer>
  </section>
</template>

<script>
import * as aui from "@atlassian/aui";

export default {
  props: {
    id: String,
    action: Function,
    title: String,
    description: String,
    buttonLabel: String,
  },
  methods: {
    closeForm() {
      aui.dialog2(`#${this.id}`).hide();
    },
    onOk(event) {
      this.action(event, aui.dialog2(`#${this.id}`));
    },
  },
};
</script>