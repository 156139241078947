<template>
  <div>
    <div class="aui-page-header" style="margin-left: 5rem">
      <div class="aui-page-header-inner">
        <div class="aui-page-header-main">
          <h3 class="component-heading">
            {{ $t("new_object_schema_import") }}
          </h3>
        </div>
      </div>
    </div>
    <div class="aui-group">
      <div class="aui-item">
        <form
          class="aui text-align-left"
          style="margin-right: 10rem; margin-left: 5rem"
        >
          <fieldset>
            <select-2-component
              v-model="object.insight_id"
              :label="$t('object_schema')"
              id="insight_object_schema_id"
              :options="insightObjectSchemas"
              :get-info="getInfo"
              :validationKeys="['InsightId', 'InsightName']"
              :validation="validation"
              :required="true"
            />
            <input-component
              :label="$t('insight_import_token')"
              id="insightImportToken"
              :required="true"
              v-model="object.insight_import_token"
              :defaultValue="object.insight_import_token"
              :validationKeys="['InsightImportToken']"
              :validation="validation"
            />
            <div class="field-group">
              <label v-if="!hideLabel" :for="id">
                <slot />
                {{ $t("cron") }} (UTC)
                <span class="aui-icon icon-required"></span>
              </label>
              <Cron
                id="cron"
                :options="options"
                style="max-width: none"
                :cron="object.cron"
                showResultText="true"
                showResultCron="true"
                :translations="$tm('cron_vue_builder')"
                :locale="locale"
                @cron-change="valChanged"
              />
              <div class="error">{{ showValidationMessage(["Cron"]) }}</div>
              <div class="description">
                {{ $t("the_cron_is_executed_considering_the_time_in_UTC") }}
              </div>
              <div class="field-group">
                <label>
                  {{ $t("create_with_default_schema") }}
                </label>
                <input v-model="object.is_with_default_schema" class="checkbox" type="checkbox" name="is_with_default_schema" id="is_with_default_schema">
                <div class="description">
                  {{ $t("default_schema_description") }}
                </div>
              </div>
            </div>
            <div class="buttons-container">
              <div class="buttons text-align-right">
                <button
                  type="button"
                  class="aui-button aui-button-primary"
                  @click="addImportSync($event, object)"
                >
                  {{ $t("save") }}
                </button>
                <a href="#" type="button" class="cancel" @click="closeForm()">{{
                  $t("cancel")
                }}</a>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Cron from "vue-cron-builder";
import HEADER from "vue-cron-builder/dist/header";
import "vue-cron-builder/dist/cron.css";
import { getValidationMessage } from "../helpers/validators.js";
import { removeSelectorMinutesOnCronUI } from "../helpers/utils.js";
import i18n from "../i18n";

export default defineComponent({
  props: {
    isEnabledObjectSchemaForm: Boolean,
    insightObjectSchemas: Array,
    closeForm: Function,
    addImportSync: Function,
    validation: Object,
  },
  components: {
    Cron,
  },
  data() {
    return {
      options: {
        headers: [HEADER.HOURLY, HEADER.DAILY, HEADER.WEEKLY, HEADER.MONTHLY],
      },
      object: {
        cron: "0 0 4 ? * MON *",
        is_with_default_schema: false
      },
      locale: i18n.global.locale,
    };
  },
  methods: {
    showValidationMessage(keys) {
      if (!this.validation) return "";
      return getValidationMessage(this.validation.errors, keys);
    },
    getInfo(object) {
      if (!object) return;
      this.object.insight_name = object.name;
    },
    valChanged(val) {
      removeSelectorMinutesOnCronUI();
      this.object.cron = val;
    },
  },
  mounted() {
    removeSelectorMinutesOnCronUI();
    this.object.cron = "0 0 0/1 1/1 * ? *";
  },
});
</script>

<style>
</style>