import { createStore } from "vuex";
import object_type from "./object_type.module";
import object_schema from "./object_schema.module";
import field from "./field.module";
import role from "./role.module";
import security from "./security.module";
import settings from "./settings.module";

const store = createStore({
  modules: {
    object_schema,
    object_type,
    field,
    role,
    security,
    settings
  },
});
export default store;