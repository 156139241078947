import { responseHelper } from "@/helpers/responseHelper";
import CONFIG from "../helpers/config";
import SettingsService from '../services/settings/settings.service';
import SettingsServiceMock from '../services/settings/settings.service.mock';

const payload: any = null;
const initialState = { status: { result: true }, response: payload }

const service = CONFIG.useMockups ? SettingsServiceMock : SettingsService;

export default {
    namespaced: true,
    state: initialState,
    actions: {
        getCredentials(store: any) {
            return responseHelper(store, 'getCredentials', service.getCredentials());
        },
        saveDevice42Credentials(store: any, payload: any) {
            return responseHelper(store, 'saveDevice42Credentials', service.saveDevice42Credentials(payload));
        }
    },
    mutations: {
        getCredentialsSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        getCredentialsFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        },
        saveDevice42CredentialsSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        saveDevice42CredentialsFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        }
    }
};