import IObjectSchemaService from "./object_schema.interface";
import getMock from "../../assets/mockups/object_schema.json";
import createMock from "../../assets/mockups/new_object_schema.json";
import createWithDefaultSchemaMock from "../../assets/mockups/new_object_schema_with_default_schema.json";
import updateMock from "../../assets/mockups/update_object_schema.json";
import syncMock from "../../assets/mockups/sync.json";
import syncStatusMock from "../../assets/mockups/sync_status.json";
import insightObjectSchemas from "../../assets/mockups/object_schema_dropdown.json";

class ObjectSchemaServiceMock implements IObjectSchemaService {

    async get() {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: getMock });
        });
    }
    async create(payload: any) {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: payload.is_with_default_schema ? createWithDefaultSchemaMock : createMock });
        });
    }
    async update(id: number, payload: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: updateMock });
        });
    }
    async delete(id: number, payload: any) {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: {} });
        });
    }
    async sync(id: number, payload: any) {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: syncMock });
        });
    }
    async syncStatus(url: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: syncStatusMock });
        });
    }
    async insightObjectSchemas(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: insightObjectSchemas });
        });
    }
}

export default new ObjectSchemaServiceMock();