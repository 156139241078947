import ISettingsService from "./settings.interface";
import getCredentialsMock from "../../assets/mockups/settings.json"
import saveCredentialsMock from "../../assets/mockups/save_settings.json"

class SettingsServiceMock implements ISettingsService {
    async getCredentials(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: getCredentialsMock});
        });
    }
    async saveDevice42Credentials(payload: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            resolve({ result: true, data: saveCredentialsMock});
        });
    }
    
    
}

export default new SettingsServiceMock();