<script>
import { defineComponent } from "vue";
import Accordion from "primevue/accordion";

export default defineComponent({
  extends: Accordion,
  methods: {
    getTabHeaderClass(tab, i) {
      const headerClasses = Accordion.methods.getTabHeaderClass.call(
        this,
        tab,
        i
      );
      const variantClass = this.getCurrentVariantClass(tab.props.variant);
      return headerClasses.concat(variantClass);
    },
    getCurrentVariantClass(value) {
      if (value == "FAILED")
        return"p-accordion-header-variant";
      return "";
    },
  },
  render: Accordion.render,
});
</script>

<style scoped>
.p-accordion-header-variant .p-accordion-header-link {
  background: #ffcccb !important;
}
</style>
