<template>
  <section
    :id="id"
    class="sync-status aui-dialog2 aui-dialog2-medium aui-layer"
    role="dialog"
    tabindex="-1"
    aria-labelledby="dialog-show-button--heading"
    hidden
  >
    <header class="aui-dialog2-header">
      <h1 class="aui-dialog2-header-main" id="dialog-show-button--heading">
        {{ $t("sync_status") }}
      </h1>
      <button
        class="aui-close-button"
        type="button"
        aria-label="close"
      ></button>
    </header>
    <div v-if="!loading" class="aui-dialog2-content">
      <div v-if="syncStatus">
        <div v-html="getStatus(syncStatus)"></div>
        <p>
          {{ $t("object_schema") }}: <b>{{ objectSchemaName }}</b>
        </p>
      </div>
      <small
        >{{
          $t("the_information_will_be_updated_automatically_every_x_seconds", {
            seconds: timeRequestNewInfoInSeconds,
          })
        }}
      </small>
      <div
        :class="
          'aui-message aui-message-' + getMessageAccordingToStatus(syncStatus)
        "
      >
        <div v-if="syncStatus">
          <p>
            <b>{{ $t("created") }}: </b
            >{{ getFormatterDate(syncStatus.created_at) }}
          </p>
          <p v-if="syncStatus.status.toLowerCase() != Status.LAUNCHED">
            {{
              $t("processing_step_x_of_y", {
                first_step: syncStatus.current_step,
                last_step: syncStatus.total_step,
              })
            }}
          </p>
          <p>
            <b>{{ $t("uuid") }}: </b
            >{{ syncStatus.uuid }}
          </p>
          <p>
            <b>{{ $t("sync_type") }}: </b
            >{{ $t(`backend.${syncStatus.type}`) }}
          </p>
          <p v-if="syncStatus.status.toLowerCase() == Status.IN_PROGRESS">
            <b>{{ $t("currently") }}:</b>
            {{ $t(syncStatus.current_step_message, { object_type: syncStatus.current_object_type}) }}
          </p>
          <p
            v-if="
              syncStatus.status.toLowerCase() == Status.IN_PROGRESS ||
              syncStatus.status.toLowerCase() == Status.DONE_WITH_ERRORS ||
              syncStatus.status.toLowerCase() == Status.DONE
            "
          >
            <b
              >{{
                $t(
                  syncStatus.status.toLowerCase() == Status.IN_PROGRESS
                    ? "processing"
                    : "processed"
                )
              }}:</b
            >

            {{ $t("processing_message", {
              processed_items: syncStatus.total_objects_processed,
              total_items: syncStatus.total_objects_to_sync
            }) }}
            
          </p>
          <p v-if="syncStatus.start_at">
            <b>{{ $t("started") }}:</b>
            {{ getFormatterDate(syncStatus.start_at) }}
          </p>
          <p v-if="syncStatus.ended_at">
            <b>{{ $t("ended") }}:</b>
            {{ getFormatterDate(syncStatus.ended_at) }}
          </p>
          <p v-if="syncStatus.start_at && syncStatus.execution_time">
            <b>{{ $t("execution_time") }}:</b>
            {{ getDurationTimeHumanize(syncStatus.execution_time) }}
          </p>
          <p v-if="syncStatus.status.toLowerCase() == Status.FAILED">
            <b>{{ $t("error_message") }}:</b> {{ syncStatus.failed_message }}
          </p>
          <p
            v-if="
              syncStatus.status.toLowerCase() == Status.DONE_WITH_ERRORS
            "
          >
            <b>{{ $t("failed_objects") }}:</b>
            {{ syncStatus.total_objects_failed }}
          </p>
          <p v-if="showDonwload(syncStatus)">
            <a class="cancel" :href="syncStatus.logs" target="_blank" type="button">
              {{ $t("download_logs") }}
            </a>
          </p>
        </div>
      </div>
    </div>
    <div v-else class="container" style="min-height: 25vh">
      <loading-component />
    </div>
    <footer class="aui-dialog2-footer">
      <div class="aui-dialog2-footer-actions">
        <a href="#" type="button" class="cancel" @click="closeForm()">{{
          $t("close")
        }}</a>
      </div>
    </footer>
  </section>
</template>
<script>
import { defineComponent } from "vue";
import * as aui from "@atlassian/aui";
import { showAlert } from "../helpers/utils";
import SYNC_STATUS from "../helpers/syncStatus";
import CONFIG from "../helpers/config";
import moment from "moment";

export default defineComponent({
  props: {
    id: String,
    objectSchemaName: String,
    linkSyncStatus: String,
    closeDialogSyncStatus: Function,
  },
  data() {
    return {
      timeRequestNewInfoInSeconds: CONFIG.timeRequestNewInfoInSeconds,
      loading: true,
      isError: false,
      isOpenDialog: true,
      syncStatus: null,
      Status: SYNC_STATUS,
    };
  },
  methods: {
    closeForm() {
      aui.dialog2(`#${this.id}`).hide();
    },
    getFormatterDate(date) {
      return moment(date).format("yyyy-MM-DD hh:mm a");
    },
    getDurationTimeHumanize(execution_time) {
      return moment.duration(execution_time, "seconds").humanize();
    },
    getStatus(syncStatus) {
      if (!syncStatus) return "";
      if (syncStatus.status.toLowerCase() == SYNC_STATUS.LAUNCHED)
        return (
          '<span class="aui-lozenge aui-lozenge-new">' +
          this.$t("launched") +
          "</span>"
        );
      if (syncStatus.status.toLowerCase() == SYNC_STATUS.IN_PROGRESS)
        return (
          '<span class="aui-lozenge aui-lozenge-inprogress">' +
          this.$t("in_progress") +
          "</span>"
        );
      if (syncStatus.status.toLowerCase() == SYNC_STATUS.DONE)
        return (
          '<span class="aui-lozenge aui-lozenge-success">' +
          this.$t("done") +
          "</span>"
        );
      if (syncStatus.status.toLowerCase() == SYNC_STATUS.FAILED)
        return (
          '<span class="aui-lozenge aui-lozenge-error">' +
          this.$t("failed") +
          "</span>"
        );
      if (syncStatus.status.toLowerCase() == SYNC_STATUS.DONE_WITH_ERRORS)
        return (
          '<span class="aui-lozenge aui-lozenge-subtle aui-lozenge-moved">' +
          this.$t("complete_with_errors") +
          "</span>"
        );
      return (
        '<span class="aui-lozenge aui-lozenge-warning">' +
        this.$t("error") +
        "</span>"
      );
    },
    getMessageAccordingToStatus(syncStatus) {
      if (!syncStatus) return "warning";
      if (syncStatus.status.toLowerCase() == SYNC_STATUS.LAUNCHED)
        return "change";
      if (syncStatus.status.toLowerCase() == SYNC_STATUS.IN_PROGRESS)
        return "info";
      if (syncStatus.status.toLowerCase() == SYNC_STATUS.DONE) return "success";
      if (syncStatus.status.toLowerCase() == SYNC_STATUS.FAILED) return "error";
      if (syncStatus.status.toLowerCase() == SYNC_STATUS.DONE_WITH_ERRORS)
        return "warning";
      return "warning";
    },
    getIconAccordingToStatus(status) {
      if (status.toLowerCase() == SYNC_STATUS.IN_PROGRESS)
        return "aui-iconfont-running-build";
      if (status.toLowerCase() == SYNC_STATUS.DONE)
        return "aui-iconfont-check-circle-filled";
      if (status.toLowerCase() == SYNC_STATUS.FAILED)
        return "aui-iconfont-warning";
      return "aui-iconfont-queued-build";
    },
    getSyncStatus() {
      if (this.isError) return;
      const self = this;

      this.$store
        .dispatch("object_schema/syncStatus", this.linkSyncStatus)
        .then(
          (response) => {
            self.syncStatus = response;
          },
          (error) => {
            showAlert("error", error.message);
            self.isError = true;
          }
        )
        .finally(() => {
          self.loading = false;
          if (
            !self.isError &&
            self.isOpenDialog &&
            [SYNC_STATUS.LAUNCHED, SYNC_STATUS.IN_PROGRESS].includes(self.syncStatus.status.toLowerCase())
          ) {
            setTimeout(() => {
              self.getSyncStatus();
            }, CONFIG.timeRequestNewInfo);
          }
        });
    },
    showDonwload(syncStatus) {
      if (!syncStatus) return false;
      return (
        !!syncStatus.logs
      );
    },
  },
  mounted() {
    const self = this;
    aui.dialog2(`#${this.id}`).on("hide", function () {
      self.isOpenDialog = false;
      self.closeDialogSyncStatus();
    });
    if (!this.linkSyncStatus) {
      this.loading = false;
      return;
    }
    this.getSyncStatus();
  },
});
</script>

<style>
</style>