<template>
  <div v-if="!loading">
    <div class="aui-page-header" style="margin-left: 5rem">
      <div class="aui-page-header-inner">
        <div class="aui-page-header-main">
          <h3 class="component-heading">{{ $t("new_object_sync") }}</h3>
        </div>
      </div>
    </div>
    <div class="aui-group">
      <div class="aui-item">
        <form class="aui text-align-left">
          <fieldset>
            <text-area-component
              v-model="object.doql"
              label="DOQL"
              id="doql"
              :required="true"
              :validationKeys="['DOQL']"
              :validation="validation"
              :description="$t('doql_description')"
            />
            <div v-if="!is_new_object_type">
              <select-2-component
                v-model="object.insight_id"
                :label="$t('object_type')"
                id="insight_object_type_id"
                :options="dropdowns.object_types"
                :get-info="getInfo"
                :required="true"
                :validationKeys="[
                  'InsightId',
                  'InsightName',
                  'IconJson',
                  'ObjectSchemaImportId',
                ]"
                :validation="validation"
              >
                <a
                  v-if="isAdmin"
                  href="#"
                  type="button"
                  @click="setNewObjectType(true)"
                  ><span
                    class="aui-icon aui-icon-small aui-iconfont-add"
                    :title="$t('create_new_object_type_in_insight')"
                  ></span
                ></a>
              </select-2-component>
            </div>
            <div v-if="is_new_object_type">
              <input-component
                v-model="object.insight_name"
                label="Object Type Name"
                id="insight_name"
                :required="true"
                :validationKeys="['InsightName']"
                :validation="validation"
              >
                <a
                  v-if="isAdmin"
                  href="#"
                  type="button"
                  @click="setNewObjectType(false)"
                  ><span
                    class="aui-icon aui-icon-small aui-iconfont-cross"
                    :title="$t('close')"
                  ></span
                ></a>
              </input-component>
              <select-2-component
                v-model="object.icon_id"
                :label="$t('icon')"
                id="icon_id"
                :options="dropdowns.icons"
                :required="true"
                :validationKeys="['IconId']"
                :validation="validation"
              />
            </div>
            <div class="buttons-container">
              <div class="buttons text-align-right">
                <button
                  type="button"
                  class="aui-button aui-button-primary"
                  @click="addObjectSync($event, index, object)"
                >
                  {{ $t("save") }}
                </button>
                <a href="#" type="button" class="cancel" @click="closeForm()">{{
                  $t("cancel")
                }}</a>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
  <div v-else>
    <loading-component />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { showAlert } from "../helpers/utils";

export default defineComponent({
  props: {
    index: Number,
    isAdmin: Boolean,
    objectSchemaImportId: Number,
    objectSchemaInsightId: String,
    addObjectSync: Function,
    closeForm: Function,
    validation: Object,
  },
  data() {
    return {
      loading: true,
      dropdowns: {
        object_types: [],
        icons: [],
      },
      object: {
        object_schema_import_id: this.objectSchemaImportId,
        object_schema_insight_id: this.objectSchemaInsightId,
        is_new_object_type: false,
      },
      is_new_object_type: false,
    };
  },
  methods: {
    getInfo(object) {
      if (!object) return;
      this.object.insight_name = object.name;
      this.object.icon_json = JSON.stringify(object.icon);
    },
    setNewObjectType(state) {
      this.is_new_object_type = state;
      this.object.is_new_object_type = state;
    },
    getObjectTypeDropdowns() {
      this.$store
        .dispatch("object_type/dropdowns", {
          object_schema_insight_id: this.objectSchemaInsightId,
        })
        .then(
          (response) => {
            this.dropdowns = response;
          },
          (error) => {
            showAlert("error", error.message);
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getObjectTypeDropdowns();
  },
});
</script>

<style>
</style>