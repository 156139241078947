import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "field-group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = {
  key: 0,
  class: "aui-icon icon-required"
}
const _hoisted_4 = ["type", "id", "name", "placeholder", "value", "defaultValue", "list"]
const _hoisted_5 = {
  key: 1,
  class: "error"
}
const _hoisted_6 = { class: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.hideLabel)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id
        }, [
          _renderSlot(_ctx.$slots, "default"),
          _createTextVNode(" " + _toDisplayString(_ctx.label), 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: "text",
      type: _ctx.type || 'text',
      id: _ctx.id,
      name: _ctx.id,
      placeholder: _ctx.placeholder,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
      defaultValue: _ctx.defaultValue,
      list: _ctx.list
    }, null, 40, _hoisted_4),
    (_ctx.validationKeys && _ctx.validationKeys.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.showValidationMessage()), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.description), 1)
  ]))
}