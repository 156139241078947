<template  v-cloak>
  <div v-if="!loading && userRoles.length" class="custom-container">
    <nav class="aui-navgroup aui-navgroup-horizontal">
      <div class="aui-navgroup-inner">
        <div class="aui-navgroup-primary">
          <ul class="aui-nav">
            <li :class="$route.name === 'Sync' && 'aui-nav-selected'">
              <router-link to="/sync">{{ $t("sync") }}</router-link>
            </li>
            <li
              v-if="userRoles.includes('admin')"
              :class="$route.name === 'Settings' && 'aui-nav-selected'"
            >
              <router-link to="/settings">{{ $t("settings") }}</router-link>
            </li>
            <li
              v-if="userRoles.includes('admin')"
              :class="$route.name === 'Security' && 'aui-nav-selected'"
            >
              <router-link to="/security">{{ $t("security") }}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <div class="custom-container">
      <router-view />
    </div>
  </div>
  <div v-else-if="loading" class="spinner-container">
    <loading-component />
  </div>
  <div v-else class="custom-container">
    <div class="aui-message aui-message-error">
      <div class="aui-message-content">
        <p>{{ $t("you_are_not_authorized_to_access_this_page") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { isSiteAdmin, showAlert } from "./helpers/utils";

export default defineComponent({
  name: "App",
  data() {
    return {
      userRoles: [],
      loading: true,
    };
  },
  methods: {
    getRoles() {
      const self = this;
      this.$store.dispatch("role/get").then(
        async (response) => {
          self.userRoles = response;
          if (!response.includes("admin")) {
            if (await isSiteAdmin()) this.userRoles.push("admin");
          }
          this.loading = false;

        },
        (error) => {
          showAlert("error", error.message);
          this.loading = false;
        }
      );
    },
  },
  created() {
    this.getRoles();
  },
});
</script>

<style>
.spinner-container {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
