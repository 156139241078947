<template>
  <div
    v-if="!loading_dropdown && !loading_object_schema_imports"
    class="custom-container"
  >
    <div class="aui-group aui-group-split">
      <div class="aui-item"></div>
      <div class="aui-item">
        <div class="buttons-container">
          <div class="buttons">
            <button
              v-if="
                !is_enabled_object_schema_form && user_roles.includes('admin')
              "
              class="aui-button aui-button-primary"
              id="button-spin-2"
              @click="
                () => {
                  is_enabled_object_schema_form = true;
                }
              "
            >
              {{ $t("add_import") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <object-schema-form-component
      id="object-schema-form-component"
      v-if="!loading_dropdown && is_enabled_object_schema_form"
      :is-enabled-object-schema-form="is_enabled_object_schema_form"
      :insight-object-schemas="insight_object_schemas"
      :add-import-sync="addImport"
      :validation="object_schema_import_validation"
      :close-form="() => (is_enabled_object_schema_form = false)"
    />
    <div v-if="object_schemas.length > 0" class="custom-container">
      <div class="aui-page-header">
        <div class="aui-page-header-inner">
          <div class="aui-page-header-main">
            <h1 class="component-heading">
              {{ $t("object_scheme_import") }}
            </h1>
          </div>
        </div>
      </div>
      <div class="custom-container">
        <Accordion
          :activeIndex="activeIndexObjectSchemaImport"
          @tab-open="selectedTabObjectSchema"
        >
          <AccordionTab
            v-for="(object_schema, index) in object_schemas"
            :key="object_schema.id"
            :variant="object_schema.status"
          >
            <template #header>
              <span>{{ object_schema.insight_name }}</span>
              <IconInfoComponent
                v-if="object_schema.status == 'FAILED' && object_schema.message && object_schema.message != ''"
                :identifier="'schema-info-' + object_schema.insight_id"
                :message="object_schema.message"
              />
            </template>
            <div
              v-for="(schedule, sche_index) in object_schema.schedules"
              :key="'schedule-' + index + '-' + sche_index"
            >
              <div class="aui">
                <div class="aui custom-container">
                  <div class="buttons-custom-container">
                    <div class="buttons text-align-right">
                      <button
                        v-if="schedule.link_status"
                        class="aui-button aui-button-link aui-button-link-icon-text"
                        @click="
                          getSyncStatus(
                            $event,
                            object_schema.insight_name,
                            schedule
                          )
                        "
                      >
                        <span
                          class="aui-icon aui-icon-small aui-iconfont-configure"
                        ></span>
                        {{ $t("sync_status") }}
                      </button>
                      <button
                        v-if="user_roles.includes('admin')"
                        class="aui-button aui-button-primary"
                        id="button-spin-2"
                        @click="
                          () => {
                            object_schema_index = index + 1;
                            schedule_index = sche_index + 1;
                          }
                        "
                      >
                        {{ $t("add_object_to_sync") }}
                      </button>
                      <button
                        class="aui-button green-button"
                        @click="
                          sync($event, index, sche_index, schedule.id)
                        "
                      >
                        {{ $t("sync") }}
                      </button>
                      <button
                        v-if="user_roles.includes('admin')"
                        class="aui-button"
                        @click="
                          editCron($event, index, sche_index, schedule)
                        "
                      >
                        {{ $t("edit") }}
                      </button>
                      <button
                        v-if="user_roles.includes('admin')"
                        class="aui-button"
                        @click="
                          deleteObjectSchemaImport(
                            $event,
                            index,
                            sche_index,
                            object_schema.insight_name,
                            schedule
                          )
                        "
                      >
                        {{ $t("delete") }}
                      </button>
                    </div>
                  </div>
                  <div class="cron-info-container">
                    <div class="cron-text-container">
                      <div class="aui-tabs horizontal-tabs">
                        <ul class="property-list">
                          <li class="item">
                            <div class="wrap">
                              <p class="name">
                                <b> {{ $t("insight_import_token") }}:</b>
                              </p>
                              <div
                                class="text-collapse webkit-box-text external-import-token"
                                @click="displayText($event)"
                              >
                                {{ schedule.insight_import_token }}
                              </div>
                            </div>
                          </li>
                          <li class="item">
                            <div class="wrap">
                              <p class="name">
                                <b> {{ $t("scheduled") }}:</b>
                              </p>
                              <div
                                class="text-collapse webkit-box-text"
                                @click="displayText($event)"
                              >
                                {{ getCronString(schedule.cron) }} (UTC)
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="
                      update_object_schema_index === index + 1 &&
                      update_object_schema_schedule_index === sche_index + 1
                    "
                    class="aui-group"
                  >
                    <div class="aui-item">
                      <form
                        class="aui text-align-left"
                        style="margin-right: 10rem; margin-left: 5rem"
                      >
                        <fieldset>
                          <input-component
                            :label="$t('insight_import_token')"
                            id="insightImportToken"
                            :required="true"
                            v-model="
                              update_object_schema_import.insight_import_token
                            "
                            :defaultValue="
                              update_object_schema_import.insight_import_token
                            "
                            :validationKeys="['InsightImportToken']"
                            :validation="validation"
                          />
                          <div class="field-group">
                            <label>
                              <slot />
                              {{ $t("cron") }} (UTC)
                              <span class="aui-icon icon-required"></span>
                            </label>
                            <Cron
                              :options="options"
                              style="max-width: none"
                              :cron="update_object_schema_import.cron"
                              showResultText="true"
                              showResultCron="true"
                              :translations="$tm('cron_vue_builder')"
                              :locale="locale"
                              @cron-change="valChanged"
                            />
                            <div class="error">
                              {{
                                showValidationMessage(
                                  update_object_schema_validation,
                                  ["Cron"]
                                )
                              }}
                            </div>
                            <div class="description">
                              {{
                                $t(
                                  "the_cron_is_executed_considering_the_time_in_UTC"
                                )
                              }}
                            </div>
                          </div>
                          <div class="buttons-container">
                            <div class="buttons text-align-right">
                              <button
                                type="button"
                                class="aui-button aui-button-primary"
                                @click="updateCron($event, index, sche_index)"
                              >
                                {{ $t("save") }}
                              </button>
                              <a
                                href="#"
                                type="button"
                                class="cancel"
                                @click="
                                  () => {
                                    update_object_schema_index = 0;
                                    update_object_schema_schedule_index = 0;
                                  }
                                "
                              >
                                {{ $t("cancel") }}
                              </a>
                            </div>
                          </div>
                        </fieldset>
                      </form>
                    </div>
                  </div>
                  <div
                    v-if="
                      object_schema_index === index + 1 &&
                      schedule_index === sche_index + 1
                    "
                    class="aui custom-container padding-bottom-2"
                  >
                    <object-form-component
                      :key="'object-form-' + index"
                      :index="index"
                      :isAdmin="user_roles.includes('admin')"
                      :objectSchemaImportId="schedule.id"
                      :objectSchemaInsightId="object_schema.insight_id"
                      :validation="object_type_validation"
                      :closeForm="() => (object_schema_index = 0)"
                      :addObjectSync="addObjectSync"
                    />
                  </div>
                </div>
              </div>

              <div
                v-if="schedule.object_types.length > 0"
                class="custom-container"
              >
                <Accordion
                  :activeIndex="getActiveIndexOnObjectTypes(index, sche_index)"
                  @tab-open="selectedTabObjectType"
                >
                  <AccordionTab
                    v-for="(object_type, object_index) in orderObjecetTypes(
                      schedule.object_types
                    )"
                    :key="'object-type-' + object_type.id"
                    :variant="object_type.status"
                  >
                    <template #header>
                      <span
                        class="aui-lozenge margin-right-1"
                        :class="
                          object_type.is_enabled
                            ? 'aui-lozenge-success'
                            : 'aui-lozenge-error'
                        "
                        >{{
                          object_type.is_enabled
                            ? $t("enabled")
                            : $t("disabled")
                        }}</span
                      >
                      <span>{{ object_type.insight_name }}</span>
                      <i
                        v-if="user_roles.includes('admin')"
                        class="pi pi-trash margin-left-1"
                        @click="
                          deleteObjectType(
                            $event,
                            index,
                            sche_index,
                            object_index,
                            object_type
                          )
                        "
                      ></i>
                      <IconInfoComponent
                        v-if="object_type.status == 'FAILED' && object_type.message && object_type.message != ''"
                        :identifier="'objet-type-info-' + object_type.id"
                        :message="object_type.message"
                      />
                    </template>
                    <div class="aui custom-container">
                      <object-component
                        :key="'object-type-' + object_index"
                        :index="object_type.id"
                        :isAdmin="user_roles.includes('admin')"
                        :isEnabled="object_type.is_enabled"
                        :objectSchemaIndex="index"
                        :scheduleIndex="sche_index"
                        :objectTypeIndex="object_index"
                        :objectSchemaIndexSelected="objectSchemaIndexSelected"
                        :objectTypeIndexSelected="objectTypeIndexSelected"
                        :objectType="object_type"
                        :updateObjectType="updateObjectType"
                      />
                    </div>
                  </AccordionTab>
                </Accordion>
              </div>
              <div class="custom-container" v-else>
                <div class="aui-group aui-group-trio">
                  <div class="aui-item"></div>
                  <div class="aui-item">
                    <i>{{ $t("there_are_no_objects_to_display") }}</i>
                  </div>
                  <div class="aui-item"></div>
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    </div>
    <div class="custom-container" v-else>
      <div class="aui-group aui-group-trio">
        <div class="aui-item"></div>
        <div class="aui-item">
          <i>{{ $t("there_are_no_object_schema_import_to_display") }}</i>
        </div>
        <div class="aui-item"></div>
      </div>
    </div>
    <dialog-2-component
      id="deleteObjectSchemaImport"
      class="aui-dialog2-warning"
      :title="
        $t('delete_object_schema', {
          object_schema: this.delete_object_schema.insight_name,
        })
      "
      :description="$t('delete_object_schema_description')"
      :action="confirmDeleteObjectSchemaImport"
      :buttonLabel="$t('delete')"
    />
    <dialog-2-component
      id="deleteObjectType"
      class="aui-dialog2-warning"
      :title="
        $t('delete_object_type', {
          object_type: this.delete_object_type.insight_name,
        })
      "
      :description="$t('delete_object_type_description')"
      :action="confirmDeleteObjectType"
      :buttonLabel="$t('delete')"
    />
    <sync-status-component
      v-if="open_sync_status"
      id="sync-status"
      :closeDialogSyncStatus="closeDialogSyncStatus"
      :objectSchemaName="sync_status.object_schema_name"
      :linkSyncStatus="sync_status.link"
    />
  </div>
  <div v-else class="spinner-container">
    <loading-component />
  </div>
</template>

<script>
import ObjectSchemaFormComponent from "../components/ObjectSchemaFormComponent.vue";
import ObjectFormComponent from "../components/ObjectFormComponent.vue";
import ObjectComponent from "../components/ObjectComponent.vue";
import SyncStatusComponent from "../components/SyncStatusComponent.vue";
import Accordion from "../components/MyAccordionComponent.vue";
import AccordionTab from "../components/MyAccordionTabComponent.vue";
import IconInfoComponent from "../components/IconInfoComponent.vue";

import {
  isSiteAdmin,
  removeSelectorMinutesOnCronUI,
  showAlert,
} from "../helpers/utils.js";
import * as aui from "@atlassian/aui";
import Cron from "vue-cron-builder";
import HEADER from "vue-cron-builder/dist/header";
import "vue-cron-builder/dist/cron.css";
import parser from "cron-parser";
import cronstrue from "cronstrue/i18n";
import i18n from "../i18n";
import * as $ from "jquery";
import { getValidationMessage } from "../helpers/validators.js";

export default {
  components: {
    ObjectSchemaFormComponent,
    ObjectFormComponent,
    ObjectComponent,
    SyncStatusComponent,
    Cron,
    Accordion,
    AccordionTab,
    IconInfoComponent
  },
  data() {
    return {
      objectSchemaIndexSelected: 0,
      objectTypeIndexSelected: null,
      activeIndexObjectSchemaImport: 0,
      activeIndexObjectType: {},
      options: {
        headers: [HEADER.HOURLY, HEADER.DAILY, HEADER.WEEKLY, HEADER.MONTHLY],
      },
      loading_dropdown: true,
      loading_object_schema_imports: true,
      is_enabled_object_schema_form: false,
      insight_object_schemas: [],
      object_schemas: [],
      object_schema: {},
      object_schema_index: 0,
      schedule_index: 0,
      delete_object_schema: {
        insight_name: null,
      },
      delete_object_type: {
        insight_name: null,
      },
      user_roles: [],
      open_sync_status: false,
      sync_status: {
        object_schema_name: "",
      },
      object_schema_import_validation: null,
      object_type_validation: null,
      update_object_schema_index: 0,
      update_object_schema_schedule_index: 0,
      update_object_schema_import: {
        cron: null,
        insight_import_token: null,
      },
      update_object_schema_validation: null,
      locale: i18n.global.locale,
    };
  },
  methods: {
    selectedTabObjectSchema(event) {
      this.objectSchemaIndexSelected = event.index;
    },
    selectedTabObjectType(event) {
      this.objectTypeIndexSelected = event.index;
    },
    getActiveIndexOnObjectTypes(index, sche_index) {
      if (this.activeIndexObjectType[index])
        return this.activeIndexObjectType[index][sche_index];
      else return null;
    },
    showValidationMessage(validation, keys) {
      if (!validation) return "";
      return getValidationMessage(validation.errors, keys);
    },
    addImport(event, object) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      this.$store
        .dispatch("object_schema/create", object)
        .then(
          (response) => {
            if (
              this.object_schemas.some(
                (x) => x.insight_id == response.insight_id
              )
            ) {
              this.object_schemas
                .filter((x) => x.insight_id == response.insight_id)[0]
                .schedules.push({
                  id: response.id,
                  insight_import_token: response.insight_import_token,
                  cron: response.cron,
                  object_types: response.object_types,
                  object_schema_syncs: response.object_schema_syncs,
                });
              this.activeIndexObjectSchemaImport =
                this.object_schemas.findIndex(
                  (x) => x.insight_id == response.insight_id
                );
              this.objectSchemaIndexSelected =
                this.activeIndexObjectSchemaImport;
            } else {
              this.object_schemas = [
                ...this.object_schemas,
                {
                  insight_id: response.insight_id,
                  insight_name: response.insight_name,
                  schedules: [
                    {
                      id: response.id,
                      insight_import_token: response.insight_import_token,
                      cron: response.cron,
                      object_types: response.object_types,
                      object_schema_syncs: response.object_schema_syncs,
                    },
                  ],
                },
              ];
              this.$forceUpdate();
              this.activeIndexObjectSchemaImport =
                this.object_schemas.length - 1;
              this.objectSchemaIndexSelected =
                this.activeIndexObjectSchemaImport;
            }
            this.is_enabled_object_schema_form = false;
            showAlert(
              "success",
              this.$t("the_object_schema_was_successfully_registered")
            );
            this.object_schema_import_validation = null;
            this.$forceUpdate();
          },
          (error) => {
            if (error.status_code === 504 && object.is_with_default_schema) {
              showAlert(
                "success",
                this.$t("the_object_schema_is_processing_the_default_schema")
              );
              sessionStorage.setItem(
                "last_default_schema_registered",
                new Date()
              );
              this.is_enabled_object_schema_form = false;
              this.refreshPage();
              this.object_schema_import_validation = null;
              this.$forceUpdate();
            } else {
              showAlert("error", error.message);
              this.object_schema_import_validation = error;
            }
          }
        )
        .finally(() => {
          event.target.idle();
        });
    },
    refreshPage() {
      const last_default_schema_registered = sessionStorage.getItem(
        "last_default_schema_registered"
      );
      if (!last_default_schema_registered) return;
      const now = new Date();
      const diffTime = now - new Date(last_default_schema_registered);
      const diffMins = Math.round(((diffTime % 86400000) % 3600000) / 60000);
      if (diffMins < 3) {
        setTimeout(() => {
          this.getObjectSchemas();
          this.refreshPage();
        }, 30000);
      } else {
        sessionStorage.removeItem("last_default_schema_registered");
      }
    },
    addObjectSync(event, index, object) {
      if (event.target.isBusy()) {
        return;
      }
      let activeIndexObjectType = null;
      event.target.busy();
      this.$store
        .dispatch("object_type/create", object)
        .then(
          (response) => {
            this.object_schemas[index].schedules
              .filter((x) => x.id == object.object_schema_import_id)[0]
              .object_types.push(response);
            this.object_schema_index = 0;
            this.schedule_index = 0;
            this.object_type_validation = null;
            const number_object_types_by_schedule = this.object_schemas[
              index
            ].schedules.filter((x) => x.id == object.object_schema_import_id)[0]
              .object_types.length;
            const schedule_index = this.object_schemas[
              index
            ].schedules.findIndex(
              (x) => x.id == object.object_schema_import_id
            );
            this.activeIndexObjectType[index] = {};
            this.activeIndexObjectType[index][schedule_index] =
              number_object_types_by_schedule - 1;
            activeIndexObjectType = number_object_types_by_schedule - 1;
            this.$forceUpdate();
            showAlert(
              "success",
              this.$t("the_object_was_successfully_registered")
            );
          },
          (error) => {
            showAlert("error", error.message);
            this.object_type_validation = error;
          }
        )
        .finally(() => {
          event.target.idle();
          activeIndexObjectType !== null &&
            (this.objectTypeIndexSelected = activeIndexObjectType);
        });
    },
    deleteObjectSchemaImport(
      event,
      index,
      schedule_index,
      insight_name,
      schedule
    ) {
      event.stopPropagation();
      this.delete_object_schema = {
        id: schedule.id,
        insight_name: insight_name,
        object_schemas_index: index,
        schedule_index: schedule_index,
      };
      aui.dialog2("#deleteObjectSchemaImport").show();
    },
    confirmDeleteObjectSchemaImport(event, dialogInstance) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      this.$store
        .dispatch("object_schema/delete", { id: this.delete_object_schema.id })
        .then(
          (response) => {
            const object_schema = this.delete_object_schema;
            this.object_schemas[
              object_schema.object_schemas_index
            ].schedules.splice(object_schema.schedule_index, 1);
            if (
              this.object_schemas[object_schema.object_schemas_index].schedules
                .length === 0
            ) {
              this.object_schemas.splice(object_schema.object_schemas_index, 1);
            }
            showAlert(
              "success",
              this.$t("object_schema_deleted_successfully", {
                object_schema: object_schema.insight_name,
              })
            );
            dialogInstance.hide();
            this.$forceUpdate();
          },
          (error) => {
            showAlert("error", error.message);
          }
        )
        .finally(() => {
          event.target.idle();
        });
    },
    deleteObjectType(
      event,
      object_schema_index,
      sche_index,
      object_type_index,
      object_type
    ) {
      event.stopPropagation();
      this.delete_object_type = {
        id: object_type.id,
        insight_name: object_type.insight_name,
        object_types_index: object_type_index,
        sche_index: sche_index,
        object_schemas_index: object_schema_index,
      };
      aui.dialog2("#deleteObjectType").show();
    },
    confirmDeleteObjectType(event, dialogInstance) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      this.$store
        .dispatch("object_type/delete", { id: this.delete_object_type.id })
        .then(
          (response) => {
            const object_type = this.delete_object_type;
            showAlert(
              "success",
              this.$t("object_type_deleted_successfully", {
                object_type: object_type.insight_name,
              })
            );
            this.object_schemas[object_type.object_schemas_index].schedules[
              object_type.sche_index
            ].object_types.splice(object_type.object_types_index, 1);
            this.delete_object_type = {
              insight_name: null,
            };
            dialogInstance.hide();
            this.$forceUpdate();
          },
          (error) => {
            showAlert("error", error.message);
          }
        )
        .finally(() => {
          event.target.idle();
        });
    },
    updateObjectType(
      objectSchemaIndex,
      scheduleIndex,
      objectTypeIndex,
      objectType
    ) {
      this.object_schemas[objectSchemaIndex].schedules[
        scheduleIndex
      ].object_types[objectTypeIndex].is_enabled = objectType.is_enabled;
    },
    sync(event, index, schedule_index, object_schema_import_id) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      this.$store
        .dispatch("object_schema/sync", { id: object_schema_import_id })
        .then(
          (response) => {
            this.object_schemas[index].schedules[schedule_index].link_status =
              response.status_link;
            showAlert("success", response.message);
          },
          (error) => {
            showAlert("error", error.message);
          }
        )
        .finally(() => {
          event.target.idle();
        });
    },
    getCronString(val) {
      return cronstrue.toString(val, { locale: i18n.global.locale });
    },
    getNextSync(val) {
      return parser.parseExpression(val).next().toString();
    },
    getRoles() {
      const self = this;
      this.$store.dispatch("role/get").then(
        async (response) => {
          self.user_roles = response;
          if (!response.includes("admin")) {
            if (await isSiteAdmin()) this.user_roles.push("admin");
          }
        },
        (error) => {
          showAlert("error", error.message);
        }
      );
    },
    getInsightObjectSchemas() {
      this.$store
        .dispatch("object_schema/insightObjectSchemas")
        .then(
          (response) => {
            this.insight_object_schemas = response;
          },
          (error) => {
            showAlert("error", error.message);
          }
        )
        .finally(() => {
          this.loading_dropdown = false;
        });
    },
    getObjectSchemas() {
      const self = this;
      this.$store.dispatch("object_schema/get").then(
        (response) => {
          self.object_schemas = response;
          self.setLinkStatus(this.object_schemas);
        },
        (error) => {
          showAlert("error", error.message);
          self.loading_object_schema_imports = false;
        }
      );
    },
    setLinkStatus(objectSchemas) {
      const self = this;
      objectSchemas.map((object_schema) => {
        object_schema.schedules.map((schedule) => {
          schedule.link_status = self.getLinkStatus(schedule);
        });
      });
      self.loading_object_schema_imports = false;
    },
    getLinkStatus(schedule) {
      if (schedule.link_status) return schedule.link_status;
      const length = schedule.object_schema_syncs.length;
      if (length == 0) return null;
      const lastObjectSchemaSync = schedule.object_schema_syncs.sort(function (
        a,
        b
      ) {
        return b.id - a.id;
      })[0];
      return `object-schema-imports/${schedule.id}/sync/${lastObjectSchemaSync.uuid}`;
    },
    getSyncStatus(event, object_schema_name, schedule) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      this.sync_status = {
        object_schema_name: object_schema_name,
        link: schedule.link_status,
      };
      this.open_sync_status = true;
      setTimeout(() => {
        aui.dialog2("#sync-status").show();
        event.target.idle();
      }, 200);
    },
    closeDialogSyncStatus() {
      this.open_sync_status = false;
    },
    editCron(event, index, schedule_index, schedule) {
      removeSelectorMinutesOnCronUI();
      this.update_object_schema_import.cron = schedule.cron;
      this.update_object_schema_import.insight_import_token =
        schedule.insight_import_token;
      this.update_object_schema_import.id = schedule.id;
      this.update_object_schema_validation = null;
      this.update_object_schema_index = index + 1;
      this.update_object_schema_schedule_index = schedule_index + 1;
    },
    valChanged(val) {
      removeSelectorMinutesOnCronUI();
      this.update_object_schema_import.cron = val;
    },
    updateCron(event, index, schedule_index) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      this.$store
        .dispatch("object_schema/update", this.update_object_schema_import)
        .then(
          (response) => {
            this.object_schemas[index].schedules[schedule_index].cron =
              response.cron;
            this.object_schemas[index].schedules[
              schedule_index
            ].insight_import_token = response.insight_import_token;
            showAlert(
              "success",
              this.$t("the_object_schema_was_successfully_updated")
            );
            this.update_object_schema_import = {
              cron: null,
              insight_import_token: null,
            };
            this.update_object_schema_index = 0;
            this.update_object_schema_schedule_index = 0;
          },
          (error) => {
            showAlert("error", error.message);
            this.update_object_schema_validation = error;
          }
        )
        .finally(() => {
          event.target.idle();
        });
    },
    displayText(event) {
      const target = $(event.target);
      if (target.hasClass("webkit-box-text"))
        target.removeClass("webkit-box-text");
      else target.addClass("webkit-box-text");
    },
    orderObjecetTypes(objectTypes) {
      return objectTypes.sort((first, second) => first.id - second.id);
    },
  },
  mounted() {
    this.refreshPage();
    this.getRoles();
    this.getObjectSchemas();
    this.getInsightObjectSchemas();
  },
};
</script>

<style scoped>
@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css");
.cron-info-container {
  display: flex;
  justify-content: space-between;
}
.panel-default > .panel-heading {
  background-color: #000;
}

.p-accordion-header-link:hover {
  text-decoration: none !important;
}
.p-accordion-header-link:focus {
  text-decoration: none !important;
}
</style>
