<template>
  <section
    :id="dialogId"
    class="aui-dialog2 aui-dialog2-large aui-layer"
    role="dialog"
    tabindex="-1"
    aria-labelledby="dialog-show-button--heading"
    hidden
  >
    <header class="aui-dialog2-header">
      <h1 class="aui-dialog2-header-main" id="dialog-show-button--heading">
        {{ $t("create_new_attribute_in_insight") }}
      </h1>
      <button
        class="aui-close-button"
        type="button"
        aria-label="close"
      ></button>
    </header>
    <div class="aui-dialog2-content">
      <form class="aui">
        <fieldset>
          <input-component
            v-model="attribute.name"
            :label="$t('name')"
            id="attribute_name"
            :required="true"
            :validationKeys="['Name']"
            :validation="validation"
          />
          <input-component
            v-model="attribute.description"
            :label="$t('description')"
            id="attribute_description"
            :validationKeys="['Description']"
            :validation="validation"
          />
          <select-2-component
            v-model="attribute.default_type_id"
            :label="$t('type_value')"
            id="attribute_type_value"
            :options="typeAttributeValues"
            :required="true"
            :validationKeys="['Type', 'DefaultTypeId']"
            :validation="validation"
          />
        </fieldset>
      </form>
    </div>
    <footer class="aui-dialog2-footer">
      <div class="aui-dialog2-footer-actions">
        <button
          id="dialog-submit-button"
          class="aui-button aui-button-primary"
          @click="create($event)"
        >
          {{ $t("create") }}
        </button>
      </div>
    </footer>
  </section>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    dialogId: String,
    objectTypeInsightId: String,
    typeAttributeValues: Array,
    createNewAttributeOnInsight: Function,
    validation: Object,
  },
  data() {
    return {
      attribute: {
        type: "0",
        expand: "operations",
        insight_id: this.objectTypeInsightId
      },
    };
  },
  methods: {
    create(event) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      this.createNewAttributeOnInsight(event, this.attribute);
    },
  },
});
</script>

<style>
</style>