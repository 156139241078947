
import { defineComponent } from "vue";
import { ref } from "vue";
import { getValidationMessage } from "../helpers/validators.js";

export default defineComponent({
  props: {
    modelValue: String,
    label: String,
    id: String,
    placeholder: String,
    required: Boolean,
    description: String,
    defaultValue: String,
    type: String,
    hideLabel: Boolean,
    validationKeys: Array,
    validation: Object,
    list: String,
  },
  setup() {
    const input = ref("");
    return { input };
  },
  methods: {
    showValidationMessage() {
      if (!this.validation) return "";
      return getValidationMessage(this.validation.errors, this.validationKeys);
    },
  },
});
