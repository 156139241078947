<template>
  <div v-if="!loading" class="custom-container">
    <div class="custom-container">
      <div class="aui-page-header">
        <div class="aui-page-header-inner">
          <div class="aui-page-header-main">
            <h1 class="component-heading">{{ $t("settings") }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-container">
      <panel-component class="custom-container">
        <template #header>
          <div>

            <span>{{ $t("device42_configuration") }}</span>
            <i class="pi pi-cog margin-left-1 margin-right-1" />
            <span
              class="aui-lozenge margin-right-1"
              :class="
                this.settings.device42_status == 'ok'
                  ? 'aui-lozenge-success'
                  : 'aui-lozenge-error'
              "
            >
              {{ $t(`settings_${this.settings.device42_status}`) }}
            </span>
          </div>
        </template>
        <div class="aui custom-container">
          <div class="aui-group">
            <div class="aui-item">
              <form class="aui text-align-left">
                <input-component
                  :label="$t('device42_cloud_connector')"
                  id="device42OriginUrl"
                  :required="true"
                  v-model="settings.device42_url"
                  :defaultValue="settings.device42_url"
                  :validationKeys="['Device42Url']"
                  :validation="validation"
                  :description="$t('cloud_connector_to_enter')"
                  list="cloudConnectors"
                />
                <datalist id="cloudConnectors">
                  <option>connect.device42.io</option>
                  <option>connect-eu.device42.io</option>
                </datalist>
                <input-component
                  :label="$t('device42_verification_token')"
                  id="device42Password"
                  :required="true"
                  v-model="settings.device42_password"
                  :validationKeys="['Device42Password']"
                  :validation="validation"
                />
                <div class="buttons-container">
                  <div class="buttons text-align-right">
                    <button
                      type="button"
                      class="aui-button aui-button-primary"
                      @click="saveDevice42Credentials($event)"
                    >
                      {{ $t("save") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </panel-component>
    </div>
  </div>
  <div v-else class="spinner-container">
    <loading-component />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { showAlert } from "../helpers/utils.js";
import { validUrl } from "../helpers/validators.js";

export default defineComponent({
  data() {
    return {
      loading: true,
      settings: {
        device42_url: "",
        device42_password: "",
      },
      validation: null,
    };
  },
  methods: {
    removeProtocol() {
      if (this.settings.device42_url.includes("https://"))
        this.settings.device42_url = this.settings.device42_url.replace(
          "https://",
          ""
        );
      if (this.settings.device42_url.includes("http://"))
        this.settings.device42_url = this.settings.device42_url.replace(
          "http://",
          ""
        );
    },
    validateCloudConnector(url) {
      var pattern = new RegExp('^(connect|staging).*\\.device42\\.io$', 'i');
      return pattern.test(url);
    },
    saveDevice42Credentials(event) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      if (!this.validateCloudConnector(this.settings.device42_url)) {
        showAlert("error", this.$t("cloud_connector_valid_message"));
        event.target.idle();
        return;
      }
      if (!this.settings.device42_password) {
        showAlert("error", this.$t("password_valid_message"));
        event.target.idle();
        return;
      }
      const payload = {
        device42_url: this.settings.device42_url.includes("https://")
          ? this.settings.device42_url
          : `https://${this.settings.device42_url}`,
        device42_password: this.settings.device42_password,
      };
      this.$store
        .dispatch("settings/saveDevice42Credentials", payload)
        .then(
          (response) => {
            this.validation = null;
            showAlert(
              "success",
              this.$t("device42_credentials_were_successfully_updated")
            );
            this.removeProtocol();
            this.settings.device42_status = response.device42_status;
          },
          (error) => {
            showAlert("error", error.message);
            this.validation = error;
          }
        )
        .finally(() => {
          event.target.idle();
        });
    },
    getCredentials() {
      const self = this;
      this.$store
        .dispatch("settings/getCredentials")
        .then(
          (response) => {
            this.settings = response;
            this.removeProtocol();
          },
          (error) => {
            showAlert("error", error.message);
          }
        )
        .finally(() => {
          self.loading = false;
        });
    },
  },
  created() {
    this.getCredentials();
  },
});
</script>