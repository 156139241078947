import { responseHelper } from "@/helpers/responseHelper";
import CONFIG from "../helpers/config";
import ObjectSchemaService from '../services/object_schema/object_schema.service';
import ObjectSchemaServiceMock from '../services/object_schema/object_schema.service.mock';

const payload: any = null;
const initialState = { status: { result: true }, response: payload }

const service = CONFIG.useMockups ? ObjectSchemaServiceMock : ObjectSchemaService;

export default {
    namespaced: true,
    state: initialState,
    actions: {
        get(store: any) {
            return responseHelper(store, 'get', service.get());
        },
        create(store: any, payload: any) {
            return responseHelper(store, 'create', service.create(payload));
        },
        update(store: any, payload: any) {
            return responseHelper(store, 'update', service.update(payload.id, payload));
        },
        delete(store: any, payload: any) {
            return responseHelper(store, 'delete', service.delete(payload.id, payload));
        },
        sync(store: any, payload: any) {
            return responseHelper(store, 'sync', service.sync(payload.id, payload));
        },
        syncStatus(store: any, url: string) {
            return responseHelper(store, 'syncStatus', service.syncStatus(url));
        },
        insightObjectSchemas(store: any) {
            return responseHelper(store, 'insightObjectSchemas', service.insightObjectSchemas());
        }
    },
    mutations: {
        getSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        getFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        },
        createSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        createFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        },
        updateSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        updateFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        },
        deleteSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        deleteFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        },
        syncSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        syncFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        },
        syncStatusSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        syncStatusFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        },
        insightObjectSchemasSuccess(state: any, response: any) {
            state.status.result = true;
            state.response = response;
        },
        insightObjectSchemasFailure(state: any, response: any) {
            state.status.result = false;
            state.response = response;
        }
    }
};