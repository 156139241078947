import { fetchHelper } from "@/helpers/fetchHelper";
import IObjectTypeService from "./object_type.interface";

class ObjectTypeService implements IObjectTypeService {
    async create(payload: any) {
        return await fetchHelper('object-types', 'POST', payload);
    }
    async update(id: number, payload: any): Promise<any> {
        return await fetchHelper(`object-types/${id}`, 'PATCH', payload);
    }
    async delete(id: number, payload: any) {
        return await fetchHelper(`object-types/${id}`, 'DELETE', payload);
    }
    async updateState(id: number, payload: any): Promise<any> {
        return await fetchHelper(`object-types/${id}/update-state`, 'PATCH', payload);
    }
    async dropdowns(object_schema_insight_id: number): Promise<any> {
        return await fetchHelper(`object-schemas/${object_schema_insight_id}/object-type/dropdowns`, 'GET');
    }
}

export default new ObjectTypeService();