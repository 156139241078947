import { fetchHelper } from "@/helpers/fetchHelper";
import ISecurityService from "./security.interface";

class SecurityService implements ISecurityService {
    async getJiraGroups(): Promise<any> {
        return await fetchHelper('jira/groups', 'GET');
    }
    async getAuthorizedGroups(): Promise<any> {
        return await fetchHelper('plugin/authorized_groups', 'GET');
    }
    async updateAuthorizedGroups(payload: any): Promise<any> {
        return await fetchHelper('plugin/authorized_groups', 'PUT', payload);
    }
    
}

export default new SecurityService();