<template>
  <div class="aui">
    <div v-if="isAdmin && !open_object_type" class="aui-group aui-group-split">
      <div class="aui-item">
        <div class="buttons-container">
          <div class="buttons text-align-right">
            <button
              v-if="isEnabled"
              class="aui-button"
              @click="updateObjectTypeState($event)"
            >
              {{ $t("disable") }}
            </button>
            <button
              v-if="!isEnabled"
              class="aui-button"
              @click="updateObjectTypeState($event)"
            >
              {{ $t("enable") }}
            </button>
            <button class="aui-button" @click="open_object_type = true">
              {{ $t("edit_doql") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <EditObjectFormComponent
      v-if="open_object_type"
      :closeForm="closeForm"
      :updateObjectType="updateObjectTypeDoql"
      :objectType="objectType"
      :validation="update_object_type_validation"
    />
    <div v-if="!open_object_type" class="aui">
      <div class="mod-content">
        <div class="aui-tabs horizontal-tabs">
          <ul class="property-list">
            <li class="item">
              <div class="wrap">
                <p class="name">
                  <b> DOQL:</b>
                </p>
                <div class="value">
                  {{ object.doql }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <br />
      <fields-component
        :key="'fields-' + index"
        :index="index"
        :isAdmin="isAdmin"
        :objectType="object"
        :fields="object.fields"
        :objectSchemaIndex="objectSchemaIndex"
        :objectTypeIndex="objectTypeIndex"
        :objectSchemaIndexSelected="objectSchemaIndexSelected"
        :objectTypeIndexSelected="objectTypeIndexSelected"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { showAlert } from "../helpers/utils.js";
import FieldsComponent from "./FieldsComponent.vue";
import EditObjectFormComponent from "./EditObjectFormComponent.vue";

export default defineComponent({
  components: {
    FieldsComponent,
    EditObjectFormComponent,
  },
  props: {
    index: Number,
    isAdmin: Boolean,
    isEnabled: Boolean,
    objectSchemaIndex: Number,
    scheduleIndex: Number,
    objectTypeIndex: Number,
    objectSchemaIndexSelected: Number,
    objectTypeIndexSelected: Number,
    objectType: Object,
    updateObjectType: Function,
    
  },
  data() {
    return {
      object: this.objectType,
      update_object_type_validation: null,
      open_object_type: false
    };
  },
  methods: {
    closeForm() {
      this.open_object_type = false;
    },
    updateObjectTypeState(event) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      const self = this;
      const payload = {
        id: this.object.id,
        is_enabled: !this.object.is_enabled,
      };
      this.$store
        .dispatch("object_type/updateState", payload)
        .then(
          (response) => {
            self.updateObjectType(
              self.objectSchemaIndex,
              self.scheduleIndex,
              self.objectTypeIndex,
              response
            );
            showAlert(
              "success",
              this.$t("the_object_was_successfully_updated")
            );
          },
          (error) => {
            showAlert("error", error.message);
          }
        )
        .finally(() => {
          event.target.idle();
        });
    },
    updateObjectTypeDoql(event, payload) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      this.$store
        .dispatch("object_type/update", { id: payload.id, doql: payload.doql })
        .then(
          (response) => {
            this.object.doql = response.doql;
            showAlert(
              "success",
              this.$t("the_object_was_successfully_updated")
            );
            this.closeForm();
          },
          (error) => {
            showAlert("error", error.message);
            this.update_object_type_validation = error;
          }
        )
        .finally(() => {
          event.target.idle();
        });
    },
  },
});
</script>

<style>
</style>