<template>
  <div v-if="!loading" class="custom-container">
    <div class="custom-container">
      <div class="aui-page-header">
        <div class="aui-page-header-inner">
          <div class="aui-page-header-main">
            <h1 class="component-heading">{{ $t("security") }}</h1>
          </div>
        </div>
      </div>
    </div>
    <div class="custom-container">
      <panel-component class="custom-container">
        <template #header>
          <div>
            <span>{{ $t("security") }}</span>
            <i class="pi pi-lock margin-left-1" />
          </div>
        </template>
        <div class="aui custom-container">
          <div class="aui-group">
            <div class="aui-item">
              <form class="aui text-align-left">
                <multi-select-component
                  :label="$t('administrator_role')"
                  id="administrators"
                  :options="administrators"
                  :description="$t('these_users_will_be_able_to_manage_the_object_schema_configurations')"
                  :addElement="addAdminGroup"
                  :removeElement="removeAdminGroup"
                />
                <multi-select-component
                  :label="$t('user_role')"
                  id="users"
                  :options="users"
                  :description="$t('these_users_will_be_able_to_only_view_the_object_schema_configurations_and_perform_manual_syncs')"
                  :addElement="addUserGroup"
                  :removeElement="removeUserGroup"
                />
                <div class="buttons-container">
                  <div class="buttons text-align-right">
                    <button
                      type="button"
                      class="aui-button aui-button-primary"
                      @click="saveAuthorizedGroups($event)"
                    >
                      {{ $t("save") }}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </panel-component>
    </div>
  </div>
  <div v-else class="spinner-container">
    <loading-component />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { showAlert } from "../helpers/utils.js";

export default defineComponent({
  data() {
    return {
      loading: true,
      administrators: [],
      authorized_groups: [],
      users: [],
    };
  },
  methods: {
    saveAuthorizedGroups(event) {
      if (event.target.isBusy()) {
        return;
      }
      event.target.busy();
      const administrators = this.administrators
        .filter((x) => x.selected)
        .map((x) => ({
          jira_group_id: x.id,
          jira_group_name: x.name,
          jira_group_role: "admin",
        }));
      const users = this.users
        .filter((x) => x.selected)
        .map((x) => ({
          jira_group_id: x.id,
          jira_group_name: x.name,
          jira_group_role: "user",
        }));
      const payload = [...administrators, ...users];
      this.$store
        .dispatch("security/updateAuthorizedGroups", payload)
        .then(
          () => {
            showAlert("success", this.$t("authorized_groups_were_successfully_updated"));
          },
          (error) => {
            showAlert("error", error.message);
          }
        )
        .finally(() => {
          event.target.idle();
        });
    },
    addAdminGroup(id) {
      this.administrators.find((x) => x.id == id).selected = true;
    },
    removeAdminGroup(id) {
      this.administrators.find((x) => x.id == id).selected = false;
    },
    addUserGroup(id) {
      this.users.find((x) => x.id == id).selected = true;
    },
    removeUserGroup(id) {
      this.users.find((x) => x.id == id).selected = false;
    },
    getAuthorizedGroups() {
      const self = this;
      this.$store.dispatch("security/getAuthorizedGroups").then(
        (response) => {
          self.authorized_groups = response;
          self.getJiraGroups();
        },
        (error) => {
          showAlert("error", error.message);
          self.loading = false;
        }
      );
    },
    getJiraGroups() {
      const self = this;
      this.$store
        .dispatch("security/getJiraGroups")
        .then(
          (response) => {
            const administrator_groups_ids = self.authorized_groups
              .filter((x) => x.jira_group_role === "admin")
              .map((x) => x.jira_group_id);
            const user_groups_ids = self.authorized_groups
              .filter((x) => x.jira_group_role === "user")
              .map((x) => x.jira_group_id);
            self.administrators = response.map((group) => {
              return {
                id: group.groupId,
                text: group.name,
                selected: administrator_groups_ids.includes(group.groupId),
              };
            });
            this.users = response.map((group) => {
              return {
                id: group.groupId,
                text: group.name,
                selected: user_groups_ids.includes(group.groupId),
              };
            });
          },
          (error) => {
            showAlert("error", error.message);
          }
        )
        .finally(() => {
          self.loading = false;
        });
    },
  },
  created() {
    this.getAuthorizedGroups();
  },
});
</script>