<template>
  <div class="field-group">
    <label v-if="!hideLabel" :for="id">
      <slot />
      {{ label }}
      <span v-show="required" class="aui-icon icon-required"></span>
    </label>
    <select class="d42-select" :id="id" :name="id" :placeholder="placeholder" style="width: 100%">
      <option v-if="!hideDefaultValue" value="">{{ $t("select_an_option") }}</option>
      <option
        v-for="(option, index) in options"
        :key="option.id"
        :value="option.id"
        :data-position="index"
      >
        {{ option.name }}
      </option>
    </select>
    <div v-if="validationKeys && validationKeys.length > 0" class="error">{{ showValidationMessage() }}</div>
    <div class="description">{{ description }}</div>
  </div>
</template>

<script>
import * as aui from "@atlassian/aui";
import { getValidationMessage } from "../helpers/validators.js";

export default {
  name: "custom-select-2",
  props: {
    value: Number,
    label: String,
    id: String,
    placeholder: String,
    options: Array,
    required: Boolean,
    getInfo: Function,
    description: String,
    hideLabel: Boolean,
    hideDefaultValue: Boolean,
    validationKeys: Array,
    validation: Object
  },
  data() {
    return {
      selected: 1,
    };
  },
  methods: {
    showValidationMessage() {
      if (!this.validation) return "";
      return getValidationMessage(this.validation.errors, this.validationKeys);
    }
  },
  mounted() {
    const self = this;
    aui
      .$("select")
      .auiSelect2()
      .on("change", function (e) {
        self.$emit("update:modelValue", e.added.id);
        if (self.getInfo && e.added.id) {
          const option = self.options.filter((x) => x.id == e.added.id)[0];
          self.getInfo(option);
        }
      });
  },
};
</script>
<style>
</style>