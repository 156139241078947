import * as aui from "@atlassian/aui";
import CONFIG from '../helpers/config';
import * as FileSaver from 'file-saver';

export const showAlert = (method, body) => {
    const alert = aui.flag({
        type: method,
        body: body,
    });
    setTimeout(() => {
        alert.close();
    }, 4000)
}

export const getLocale = async() => {
    try {
        const myPreferenceLocale = await getMyPreferenceLocaleFromJira();
        const language = myPreferenceLocale.split('_')[0];
        return language;

    } catch (error) {
        console.error("Error when querying language information")
        console.error("Error: ", error)
        return "en";
    }
}

export const getMyPreferenceLocaleFromJira = async() => {
    if (CONFIG.env == "local-dev") {
        return "en_US";
    } else {
        const useLocale = new Promise((resolve) => {
            // eslint-disable-next-line no-undef
            AP.request(`/rest/api/3/mypreferences/locale`, {
                success: (response) => {
                    const localization = JSON.parse(response);
                    resolve(localization.locale)
                },
                error: (response) => {
                    resolve("en_US")
                }
            });
        });
        return await useLocale;
    }
}

export const getToken = async() => {
    if (CONFIG.env == "local-dev") {
        return CONFIG.devToken
    } else {
        // eslint-disable-next-line no-undef
        const token = await AP.context.getToken();
        return token
    }
}

export const removeSelectorMinutesOnCronUI = () => {
    setTimeout(() => {
        const selectorMinutes = aui.$(".cron_builder select.hours");
        if (selectorMinutes.length > 1) aui.$(".cron_builder select.hours")[1].remove();
    }, 5);
};

export const isSiteAdmin = async() => {
    if (CONFIG.env == "local-dev") {
        return true;
    } else {
        const getUser = new Promise((resolve) => {
            // eslint-disable-next-line no-undef
            AP.user.getCurrentUser((user) => { resolve(user) });
        });

        const user = await getUser;

        const isAdmin = new Promise((resolve) => {
            // eslint-disable-next-line no-undef
            AP.request(`/rest/api/2/user/groups?accountId=${user.atlassianAccountId}`, {
                success: (response) => {
                    const groups = JSON.parse(response);
                    resolve(groups.some((group) => group.name === 'site-admins' || group.name === 'org-admins'));
                }
            });
        });

        return await isAdmin;
    }
}

export const downloadBlob = (blob, filename) => {
    FileSaver.saveAs(blob, filename);
}