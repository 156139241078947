import { fetchHelper } from "@/helpers/fetchHelper";
import IFieldService from "./field.interface";

class FieldService implements IFieldService {
    async create(payload: any) {
        return await fetchHelper('fields', 'POST', payload);
    }
    async createOnInsight(insightId: string, payload: any) {
        return await fetchHelper(`insight/object-types/${insightId}/attribute`, 'POST', payload);
    }
    async delete(id: number, payload: any) {
        return await fetchHelper(`fields/${id}`, 'DELETE', payload);
    }
    async dropdowns(insightId: string): Promise<any> {
        return await fetchHelper(`object-types/${insightId}/field/dropdowns`, 'GET');
    }
}

export default new FieldService();

