<template>
  <div class="margin-left-1">
    <span
      class="aui-icon aui-icon-small aui-iconfont-info-filled cursor-pointer"
      data-aui-trigger
      :aria-controls="identifier"
      :href="'#' + identifier"
    ></span>
    <aui-inline-dialog :id="identifier">
      <small>{{ $t(`backend.${message}`) }}</small>
    </aui-inline-dialog>
  </div>
</template>
<script>

import * as aui from "@atlassian/aui";

export default {
  props: {
    status: String,
    identifier: String,
    message: String,
  },
  methods: {
    
  },
};
</script>
