import { createI18n } from "vue-i18n";
import en from './assets/i18n/en.json'
import es from './assets/i18n/es.json'

const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    globalInjection: true,
    messages: {
        en: en,
        es: es
    }
});

export default i18n;